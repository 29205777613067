.c-order-overview {
  display: flex;
  padding-bottom: 15rem;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }

  &__edit {
    padding: 0;
    margin: 0;
    font-size: 1.6rem;
    text-decoration: underline;
    background-color: transparent;
    border: none;
  }

  &__half {
    width: 50%;
    margin-right: 6rem;

    @media screen and (max-width: 1023px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
  &__box {
    border-radius: 1rem;
    margin-bottom: 1rem;

    header {
      padding: 1.8rem 2.5rem;
      display: flex;
      h3 {
        font-size: 1.8rem;
        margin: 0;
      }
      div {
        margin-left: 1rem;
      }
    }
    &__content {
      padding: 1.5rem 2.5rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      div {
        margin-right: 1.5rem;
        display: flex;
        flex-direction: column;

        span {
          font-size: 1.6rem;
        }
      }
    }
  }

  &__products {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.5rem;
      margin-bottom: 2rem;
      h3 {
        margin: 0;
      }
      button {

      }
    }

    &__container {

    }

    &__single {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      &__container {
        display: flex;
        align-items: center;
      }
      &__media {
        display: flex;
        justify-content: center;
        align-items: center;
        @include square-dimensions(5rem);
        overflow: hidden;
        border-radius: .8rem;
        img {
          max-width: 4.5rem;
          max-height: 4.5rem;
        }
      }
      &__amount {
        font-size: 1.6rem;
        width: 5rem;
        max-width: 5rem;
        min-width: 5rem;
        text-align: center;
      }
      &__description {
        font-size: 1.6rem;
      }
      &__price {
        font-size: 1.6rem;
        margin-left: 1rem;
      }
    }
  }

  &__discount {
    position: relative;
    input {
      padding: .5rem 4.5rem .5rem 1.8rem;
      -webkit-appearance: none;
      appearance: none;
      -ms-progress-appearance: none;
      -moz-appearance: none;
      border-radius: 1rem;
      height: 4.5rem;
      font-size: 1.5rem;
      width: 100%;
    }
    button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-color: transparent;
      border: none;
      font-size: 1.5rem;
    }
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.1rem 0 1.5rem;

    span {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
  &__terms {
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
    label {
      input {

      }
      span {
        font-size: 1.6rem;
        margin-left: 1rem;
        a {

        }
      }
    }
  }
  &__payment {
    margin: 1.6rem 0 2rem;
    label {
      font-size: 1.6rem;
    }
    .c-form__select {

    }
  }
  &__payment-button {
    button {
      width: 100%;
      text-align: center;
    }
  }
}
